import React from 'react';
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavbarText,
  Row,
  Col,
  Collapse,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button
} from "reactstrap";
import fetch from 'cross-fetch';
import Polls from "./components/Polls.jsx";
import "bootswatch/dist/flatly/bootstrap.min.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      affiliateID: '',
      affiliateIDInput: '',
      isOpen: false,
      pollsData: "",
      pollDetails: [],
      responses: [],
      adjustments: [],
      adjustmentAmount: "0"
    };

    this.createPoll = this.createPoll.bind(this);
    this.deletePoll = this.deletePoll.bind(this);
    this.updatePoll = this.updatePoll.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
  // Typical usage (don't forget to compare props):
  //console.log(this.state.affiliateID, prevState.affiliateID);
  if (this.state.affiliateID !== prevState.affiliateID) {
    fetch('https://polls.thefasttracks.com/server/server.php?method=getPolls&AffiliateID=' + this.state.affiliateID)
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res.json();
      })
      .then(user => {
        //console.log(user);
        this.setState({
          pollsData: user
        });
      })
      .catch(err => {
        //console.error(err);
      });
    }
  }

  allowPolls() {
    //console.log(this.state.affiliateID);
    return this.state.affiliateID !== '';
  }

  allowPollDetails() {
    //console.log(this.state.affiliateID);
    return this.state.pollDetails.AffiliateID === this.state.affiliateID;
  }

  handleChange = (event) => {
    //console.log(event.target.value);
    this.setState({affiliateIDInput: event.target.value});
  }

  handleSet = () => {
    //console.log(event.target.value);
    //this.setState({affiliateID: this.state.affiliateIDInput});
    if (this.state.affiliateIDInput.toLowerCase() !== this.state.affiliateID.toLowerCase()) {
      this.setState({
        pollDetails: [],
        pollsData: [],
        responses: [],
        adjustments: [],
        adjustmentAmount: "0",
        affiliateID: this.state.affiliateIDInput.toLowerCase()
      });
    }
  }

  handleAdjustmentAmountChange = (event) => {
    this.setState({adjustmentAmount: event.target.value});
  }

  handleDetailsChange(event, field) {
    //console.log(event);
    let pollDetails = this.state.pollDetails;
    //let newData = prompt("Please enter the new value");
    pollDetails[field] = event.target.value;
    this.setState({
      pollDetails
    })
  }

  adjustTotalHandler(key) {
    //console.log(key, this.state.adjustmentAmount);
    fetch('https://polls.thefasttracks.com/server/server.php?method=setAdjustment&UUID=' + this.state.pollDetails.UUID + '&Response=' + key + '&Amount=' + this.state.adjustmentAmount)
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res;
      })
      .then(user => {
        //console.log(user);
        this.pollIDCallback(this.state.pollDetails.UUID);
      })
      .catch(err => {
        //console.error(err);
      });
  }

  deleteResponseHandler(key) {
    //console.log(key, this.state.adjustmentAmount);
    fetch('https://polls.thefasttracks.com/server/server.php?method=deleteResponse&UUID=' + this.state.pollDetails.UUID + '&Response=' + key)
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res;
      })
      .then(user => {
        //console.log(user);
        this.pollIDCallback(this.state.pollDetails.UUID);
      })
      .catch(err => {
        //console.error(err);
      });
  }

  toggle() {
    this.setState ((state) => ({
      isOpen: !this.state.isOpen
    }));
    //console.log(this.state.isOpen);
  }

  getAdjustedTotal(key, total) {
    //console.log(this.state.adjustments);
    let output = 0;
    this.state.adjustments.map(adjustment => {
      if (adjustment.Response === key) {
        output = Number(adjustment.Adjustment) + Number(total);
      }
      return null;
    })
    if (output === 0) {
      output = Number(total);
    }
    return output;
  }

  renderResponses(responses) {
    //console.log(responses);
    let totals = {};
    responses.map (response => {
      //console.log(totals.indexOf(response.Response));
      //console.log(response.Response);
      if (totals.hasOwnProperty(response.Response)) {
        totals[response.Response] = totals[response.Response] + 1;
        //console.log('adding');
      } else {
        totals[response.Response] = 1;
        //console.log('creating');
      }
      return null;
    })

    let keys = Object.keys(totals);
    let output = keys.map((key, index) => {
      return (
        <Row key={index} className="mb-3">
          <Col xl="10" lg="12">
            <div style={{marginTop: -10}}><span>Real Total: <strong>{totals[key]}</strong><br/>Adjusted Total: <strong>{this.getAdjustedTotal(key, totals[key])}</strong></span> <span style={{marginLeft: 50}} className="h5">Response: {key}</span></div>
          </Col>
          <Col xl="2" lg="6">
            <Button className="float-left" color="info" onClick={() => this.adjustTotalHandler(key)}>Adjust</Button>
            <Button className="float-right" color="danger" onClick={() => this.deleteResponseHandler(key)}>Delete</Button>
          </Col>
        </Row>
      );
    });

    return <>{output}</>;
  }

  createPoll() {
    //console.log('create poll');
    fetch('https://polls.thefasttracks.com/server/server.php?method=createPoll&AffiliateID=' + this.state.affiliateID)
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res;
      })
      .then(user => {
        //console.log(user);
        fetch('https://polls.thefasttracks.com/server/server.php?method=getPolls&AffiliateID=' + this.state.affiliateID)
          .then(res => {
            if (res.status >= 400) {
              throw new Error("Bad response from server");
            }
            return res.json();
          })
          .then(user => {
            //console.log(user);
            this.setState({
              pollsData: user
            });
          })
          .catch(err => {
            //console.error(err);
          });
      })
      .catch(err => {
        console.error(err);
      });
  }

  updatePoll() {
    //console.log('update poll');
    fetch('https://polls.thefasttracks.com/server/server.php?method=updatePoll&UUID=' + this.state.pollDetails["UUID"] + '&Name=' + this.state.pollDetails["Name"] + '&VendorID=' + this.state.pollDetails["VendorID"])
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res;
      })
      .then(user => {
        //console.log(user);
        fetch('https://polls.thefasttracks.com/server/server.php?method=getPolls&AffiliateID=' + this.state.affiliateID)
          .then(res => {
            if (res.status >= 400) {
              throw new Error("Bad response from server");
            }
            return res.json();
          })
          .then(user => {
            //console.log(user);
            this.setState({
              pollsData: user
            });
          })
          .catch(err => {
            //console.error(err);
          });
      })
      .catch(err => {
        console.error(err);
      });
  }

  deletePoll(id) {
    fetch('https://polls.thefasttracks.com/server/server.php?method=deletePoll&UUID=' + id)
      .then(res => {
        if (res.status >= 400) {
          throw new Error("Bad response from server");
        }
        return res;
      })
      .then(user => {
        //console.log(user);
        this.setState({
          pollDetails: [],
          responses: [],
          adjustments: [],
          adjustmentAmount: "0"
        });
        fetch('https://polls.thefasttracks.com/server/server.php?method=getPolls&AffiliateID=' + this.state.affiliateID)
          .then(res => {
            if (res.status >= 400) {
              throw new Error("Bad response from server");
            }
            return res.json();
          })
          .then(user => {
            //console.log(user);
            this.setState({
              pollsData: user
            });
          })
          .catch(err => {
            //console.error(err);
          });
      })
      .catch(err => {
        console.error(err);
      });
  }

  pollIDCallback = (pollID) => {
    //this allows the polls table to return a poll ID
    this.state.pollsData.map (data => {
      if (data.UUID === pollID) {
        this.setState({
          pollDetails: data,
          responses: [],
          adjustments: [],
          adjustmentAmount: "0"
        });
        fetch('https://polls.thefasttracks.com/server/server.php?method=showResponses&UUID=' + pollID)
          .then(res => {
            if (res.status >= 400) {
              throw new Error("Bad response from server");
            }
            return res.json();
          })
          .then(user => {
            //console.log(user);
            this.setState({
              responses: user
            });
          })
          .catch(err => {
            //console.error(err);
          });

          fetch('https://polls.thefasttracks.com/server/server.php?method=getAdjustments&UUID=' + pollID)
            .then(res => {
              if (res.status >= 400) {
                throw new Error("Bad response from server");
              }
              return res.json();
            })
            .then(user => {
              //console.log(user);
              this.setState({
                adjustments: user
              });
            })
            .catch(err => {
              //console.error(err);
            });
      }
      return null;
    })
  }

  render() {
    // eslint-disable-next-line
    const landingSnippetOpen = `<script>var e=document.getElementsByClassName("elBTN");var r=document.getElementById("modalPopup");var n=false;function a(){return window.getComputedStyle(r).display==="none"}var o=function(){if(!n&&a()){var e=this.innerHTML;var r=e.replace(/<[^>]*>?/gm,"");var o=encodeURIComponent(r.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,""));var t=new XMLHttpRequest;t.open("GET","https://polls.thefasttracks.com/server/server.php?method=logResponse&UUID=`;
    const landingSnippetClose = `&Response="+o,true);t.send();n=true}};for(var t=0;t<e.length;t++){e[t].addEventListener("click",o,false)}</script>`;

    const chartSnippetOpen = `<iframe src="https://polls.thefasttracks.com/charting/chart.php?uuid=`;
    const chartSnippetClose = `" style="border:none; width:100%; height: 367px;"></iframe>`;

    const socialSnippetOpen = `<iframe src="https://polls.thefasttracks.com/charting/socialProof.php?uuid=`;
    const socialSnippetClose = `" scrolling="no" style="border:none; overflow: hidden; width:100%; height: 80px;"></iframe>`;

    const pollSnippetOpen = `<script>var uuid='`;
    const pollSnippetClose = `';</script>`;

    return (
      <>
      <Navbar dark expand="md" fixed="top" style={{backgroundColor: "#000000", borderBottom: "10px solid #e33d33"}}>
        <NavbarBrand href="/"><img src="/logo.png" alt="logo" height="50" style={{marginBottom: -30, marginTop: -30}} /></NavbarBrand>
        <NavbarToggler onClick={(e) => this.toggle()} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavLink href="/">Poll Management</NavLink>
          </Nav>
          <NavbarText>Copyright Precision Media Lab LLC - ALL RIGHTS RESERVED</NavbarText>
        </Collapse>
      </Navbar>
        <div className="container" style={{transform: "scale(1,1)", marginTop: 100}}>
          <Row className="mb-4">
            <Col sm="12" className="text-center"><h1>Poll Management</h1></Col>
          </Row>
          <Row className="mb-4">
            <Col sm="12" className="text-center">
              <h3>Need help using this tool? This video has everything you need to know.</h3>
              <iframe title="How to use this tool" src="https://player.vimeo.com/video/394049052" width="640" height="480" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </Col>
          </Row>
          <Row className="mb-4 row-eq-height">
            <Col xl="4" lg="12">
              <Card>
                <CardHeader>
                  <h3>CB Nick: {this.state.affiliateIDInput}</h3>
                  <p>Please enter your ClickBank Nickname below so we can create new poll counter(s) for you and retrieve any you’ve made in the past. All your poll counters and charts are linked to your ClickBank Nickname.</p>
                </CardHeader>
                <CardBody className="mt-4 mb-4">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Your Nickname:</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="affiliateid" value={this.state.affiliateIDInput} onChange={this.handleChange} />
                    <InputGroupAddon addonType="append"><Button onClick={this.handleSet} color="success">Go!</Button></InputGroupAddon>
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  This is your ClickBank affiliate Nickname, with no spaces.
                </CardFooter>
              </Card>
            </Col>
            <Col xl="8" lg="12" className="h-100">
              <Collapse isOpen={this.allowPolls()}>
                <Card className="h-100">
                  <CardHeader>
                    <h3>Your Polls:
                      <Button
                        color="success"
                        size="sm"
                        className="float-right"
                        onClick={this.createPoll}
                      >
                        Create Poll
                      </Button>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Polls data={this.state.pollsData} callback={this.pollIDCallback} delete={this.deletePoll}/>
                  </CardBody>
                  <CardFooter>
                    Have a lot of polls? This list scrolls!
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Poll Details: {this.state.pollDetails.Name}
                      <Button
                        size="sm"
                        className="float-right"
                        onClick={this.updatePoll}
                      >
                        Update Poll
                      </Button>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <InputGroup className="mb-2">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Poll Name:</InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Poll Name" value={this.state.pollDetails.Name ? this.state.pollDetails.Name : ""} onChange={(e) => this.handleDetailsChange(e,"Name")} />
                    </InputGroup>
                    <InputGroup  className="mb-2">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Vendor ID:</InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="vendorid" value={this.state.pollDetails.VendorID ? this.state.pollDetails.VendorID : ""} onChange={(e) => this.handleDetailsChange(e,"VendorID")} />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <p>Give your poll a name to help remind you which funnel it is for. You can also set a ClickBank vendor ID to remind you of which product this poll is associated with.</p>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="mt-4">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Response Management: {this.state.pollDetails.Name}
                    </h3>
                    <p>When you create a poll, you should embed it using the snippets at the bottom of this page, and then click each of your answers (you'll need to reload the poll page each time). Once you've done that, click edit on this poll in the poll list above, and you should see the answers you just clicked on show up here.</p>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-3">
                      <Col md="8">
                      </Col>
                      <Col md="4" className="text-right">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>Amount to adjust poll answer:</InputGroupText>
                          </InputGroupAddon>
                          <Input type="number" value={this.state.adjustmentAmount} onChange={this.handleAdjustmentAmountChange} />
                        </InputGroup>
                      </Col>
                    </Row>
                    {this.renderResponses(this.state.responses)}
                  </CardBody>
                  <CardFooter>
                    <p>Once the answers are showing up, you can enter a value and click the Adjust Total button to "pre-load" votes for that answer. Polls with thousands of responses are more impressive than polls with only a few!</p>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Code Snippet (Poll Page):</h3>
                    <p>This snippet goes in the Tracking Code Footer section of your landing page.</p>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: '#FFFFF0' }}>
                    {landingSnippetOpen}{this.state.pollDetails.UUID}{landingSnippetClose}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
            <Col md="6">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Code Snippet (Loading Page Chart):</h3>
                    <p>This snippet goes in a custom HTML/JS element. Place the element on the page you want your chart to show up.</p>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: '#FFFFF0' }}>
                    {chartSnippetOpen}{this.state.pollDetails.UUID}{chartSnippetClose}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="6">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Code Snippet (Poll Social Proof):</h3>
                    <p>This snippet goes in a custom HTML/JS element. Place the element on the landing page where you want to display the number of people who have taken the survey.</p>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: '#FFFFF0' }}>
                    {socialSnippetOpen}{this.state.pollDetails.UUID}{socialSnippetClose}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>
            {/*<Col md="6">
              <Collapse isOpen={this.allowPollDetails()}>
                <Card>
                  <CardHeader>
                    <h3>Code Snippet (Poll Template):</h3>
                    <p>This snippet goes in the Head Tracking Code section of your funnel's settings.</p>
                  </CardHeader>
                  <CardBody style={{ backgroundColor: '#FFFFF0' }}>
                    {pollSnippetOpen}{this.state.pollDetails.UUID}{pollSnippetClose}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Collapse>
            </Col>*/}
          </Row>
        </div>
      </>
    );
  }
}

export default App;
