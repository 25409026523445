//List of polls for affiliate ID
import React, { Component } from "react";
import { Button } from "reactstrap";

//reacttable
import Table from 'rc-table';
//reacttable

class Polls extends Component {
  editRow(row) {
    //console.log('edit ' + row.UUID);
    this.props.callback(row.UUID);
  }

  deleteRow(record) {
    //console.log('delete ' + record);
    this.props.delete(record);
  }

  render() {
    const columns = [
      {
        title: 'Poll Name',
        dataIndex: 'Name',
        key: 'ID',
        width: 400
      },
      {
        title: 'Vendor ID',
        dataIndex: 'VendorID',
        key: 'ID',
        width: 100
      },
      {
        title: 'Operations',
        dataIndex: 'UUID',
        key: 'ID',
        render: (value, row) =>
          <>
            <Button
              color="info"
              size="sm"
              onClick={() => this.editRow(row)}
            >
              Edit
            </Button>
            {" "}
            <Button
              color="danger"
              size="sm"
              onClick={() => this.deleteRow(value)}
            >
              Delete
            </Button
          ></>,
        width: 200
      },
    ];

    return (
      <>
        <Table columns={columns} scroll={{y: 209}} data={this.props.data} rowKey={record => record.ID} />
      </>
    );
  }
}

export default Polls;
